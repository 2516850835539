// extracted by mini-css-extract-plugin
export var body = "whatsinabrief-module--body--OGtya";
export var brief = "whatsinabrief-module--brief--6uSQG";
export var container = "whatsinabrief-module--container--Gye9g";
export var description = "whatsinabrief-module--description--u0VwQ";
export var first = "whatsinabrief-module--first--icJim";
export var footNote = "whatsinabrief-module--foot-note--TEiYx";
export var head = "whatsinabrief-module--head--rwMG5";
export var heading = "whatsinabrief-module--heading--eyXsW";
export var item = "whatsinabrief-module--item--+aK0O";
export var narrowOnly = "whatsinabrief-module--narrowOnly--QT4nH";
export var opening = "whatsinabrief-module--opening--ACoX2";
export var preview = "whatsinabrief-module--preview--pAZYV";
export var second = "whatsinabrief-module--second--7FoNH";
export var section = "whatsinabrief-module--section--Firfm";
export var separator = "whatsinabrief-module--separator--x0J+E";
export var spacer = "whatsinabrief-module--spacer--s6eGY";
export var summary = "whatsinabrief-module--summary--JNd8h";
export var times = "whatsinabrief-module--times--71GPf";
export var title = "whatsinabrief-module--title--2xc5G";
export var ways = "whatsinabrief-module--ways--htv8R";
export var whatItMeans = "whatsinabrief-module--whatItMeans--KZ-a0";
export var whatsHappening = "whatsinabrief-module--whatsHappening--C1cNZ";
export var wideOnly = "whatsinabrief-module--wideOnly--6xwkx";