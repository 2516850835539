import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { filter, firstVal, getIn, merge, reduce, threadLast } from '../utils/functional';
import { refreshSessionId } from '../utils/session_id';
import { jsonToJs } from '../components/brief/data';
import { content, date, tags } from '../components/brief/headline';
import { hr, vr } from '../components/brief/lines';
import { headingStyle, renderBodyItem } from '../components/brief/render/body';
import { colors } from '../components/colors';
import Layout from '../components/layout';
import * as styles from './whatsinabrief.module.css';
import minutes_ten from './whatsinabrief/minutes_ten.png';
import minutes_thirty from './whatsinabrief/minutes_thirty.png';
import minutes_three from './whatsinabrief/minutes_three.png';
import seconds_thirty from './whatsinabrief/seconds_thirty.png';

import { RenderHead } from "../components/head";
export const Head = () => RenderHead({ title: "What's in a 6Pages Brief" });



const interleave = (coll, items) => {
    // inserts items in between each element in coll
    const isLastIndex = (i) => i >= coll.length - 1;
    return reduce( (a, c, i) => {
        const cs = isLastIndex(i) ? [c] : [c, items[i]];
        return a.concat(cs);
    }, [], coll);
};



//
// brief

const publicBrief = (contentDatoms) => {
    return threadLast(
        contentDatoms,
        [filter, (c) => c.type === 'BRIEF'],
        [filter, (b) => b.body !== null && b.body !== undefined],
        firstVal );
};



//
// section: preview

const headline = (data) => {

    var s = merge(
        headingStyle(1),
        { boxShadow: 'none',
          fontSize: '28px',
          margin: 0,
          textDecoration: 'none' });
    
    return (
        <div style={{ margin: `15px 0 0` }} >
          <dev
            style={s}
            to={ data.slug_path } >
            { content(data) }
          </dev>
        </div>
    );
};

const heading = (data) => {
    return (
        <div
          style={{
              display: `flex`,
              flexDirection: `column`,
              justifyContent: `flex-start`,
              alignItems: `stretch`,
          }} >
          
          <div
            id='headline-date'
            style={{ flex: `0 0 auto` }} >
            { date(data, {fontSize: '12px'}) }
          </div>

          <div
            style={{
                flex: `0 0 auto`,
                marginTop: `6px`
            }} >
            { tags(data, { fontSize: '11px' }) }
          </div>

          <div style={{ flex: `0 0 auto` }} >
            { headline(data) }
          </div>
          
        </div>
    );
};

const docItemToJSX = (item) => {
    
    if ( item.heading !== undefined )
        return (
            <div className={ styles.heading } >
              { getIn(item, ['children', 0, 'value']) }
            </div>
        );

    return renderBodyItem(item);
};

const whatItMeansPreview = ({ body }) => {
    const f = (i) => {
        const h = getIn(i, ['heading', 'level']);
        if ( ! h || h < 2 ) return false;
        
        const v = getIn(i, ['children', 0, 'value']);
        return v.includes('Means');
    };
    const i = body.findIndex(f);
    return body.slice(i,i+2);
};

const previewDatoms = (brief) => {
    return [
        {
            body: "The 30-second version of the Brief in a few bullets.",
            footNote: "Included in Member-only email.",
            className: styles.summary,
            content: [ heading(brief),
                       brief.summary.slice(0,2).map(docItemToJSX) ],
            head: "Summary"
        },
        {
            body: "Detailed narrative describing the current events and evidence of a market shift (“signals”) – deeply researched and fully sourced. While every Brief is different, this section might cover moves by industry players, consumer and investment trends, and regulatory changes.",
            className: styles.whatsHappening,
            content: [ brief.body.slice(0,2).map(docItemToJSX) ],
            head: "What's Happening"
        },
        {
            body: "For some, this is the most interesting part of a Brief. It builds on the fact base from What’s Happening to present an analysis and forward-looking perspective on what will happen. How will the shift play out? Who will win? How big of a deal is this? Where are the opportunities?",
            className: styles.whatItMeans,
            content: [ whatItMeansPreview(brief).map(docItemToJSX) ],
            head: "What It Means"
        }
    ];
};


const sectionPreview = ({ body, className, content, footNote, head }) => {
    
    const footNoteStyle = footNote ? styles.footNote : { display: 'none' };

    const description = (
        <div>

            <div
              className={ styles.head }
              style={{ color: colors.green }} >
              { head }
            </div>

            <div className={ styles.body } >
              { body }
            </div>

            <div className={ footNoteStyle } >
              { footNote }
            </div>
            
        </div>
    );
    
    return (
        <div className={ `${styles.section} ${styles.preview} ${className}` } >

          <div className={ `${styles.description} ${styles.narrowOnly}` } >
              { description }
          </div>
            
          <div className={ `${styles.brief} ${styles.wideOnly}` } >
              { content }
          </div>

          <div className={ `${styles.spacer} ${styles.first}` } />

          <div className={ `${styles.separator} ${styles.narrowOnly}` } >
            { hr() }
          </div>

          <div className={ `${styles.separator} ${styles.wideOnly}` } >
            { vr() }
          </div>

          <div className={ `${styles.spacer} ${styles.second}` } />

          <div className={ `${styles.brief} ${styles.narrowOnly}` } >
            { content }
          </div>

          <div className={ `${styles.description} ${styles.wideOnly}` } >
            { description }
          </div>            
            
        </div>
    );
};


//
// section: ways

const timeDatoms = [
    { body: 'Read the Summary<br/>(30 seconds)',
      head: 'Very short on time',
      image: seconds_thirty },
    { body: 'Skim the bolded lines<br/>(3 minutes)',
      head: 'Have a few minutes',
      image: minutes_three },
    { body: 'Jump to the What It Means section<br/>(5-10 minutes)',
      head: 'Interested in the “so what"',
      image: minutes_ten },
    { body: 'Read the full Brief in detail<br/>(20-30 minutes)',
      head: 'On the cusp of a decision',
      image: minutes_thirty }
];

const itemTime = ({ body, head, image }) => {
    return (
        <div className={ styles.item } >

          <img
            alt={ head }
            src={ image } />
          
          <div
            className={ styles.head }
            style={{ color: colors.green }} >
            { head }
          </div>

          <div
            className={ styles.body }
            dangerouslySetInnerHTML={{ __html: body }}
          />

        </div>
    );
};


//
// component

const Whatsinabrief = ({ data, location }) => {

    useEffect(() => { refreshSessionId(); }, []);
    const cds = jsonToJs(data.contentDatoms);
    const brief = publicBrief(cds);

    return (
        <Layout location={ location } >

          <div className={ styles.container }>

            <div className={ `${styles.section} ${styles.opening}`} >

              <div className={ styles.head } >
                What to expect as a Member
              </div>

              <div className={ styles.body } >
                As a Member, you'll have unlimited access to the full-length Briefs and repository on our website. You’ll also receive our Member-only email, which includes the full-length version of each Brief as they are published along with a selection of recent Quick Hit signals.
              </div>
              
            </div>

            <div className={ `${styles.section} ${styles.title}`} >

              <div className={ styles.head } >
                What's in a 6Pages Brief
              </div>
              
            </div>

            { previewDatoms(brief).map(sectionPreview) }

            <div className={ `${styles.section} ${styles.ways}` } >

              <div className={ styles.head } >
                Ways to read a 6Pages Brief
              </div>

              <div className={ styles.times } >

                { interleave(
                    timeDatoms.map(itemTime),
                [<div className={ styles.spacer } />,
                 <div className={ styles.spacer } />,
                 <div className={ styles.spacer } />])
                }
                
              </div>
              
            </div>
            
          </div>
          
        </Layout>
    );
};
export default Whatsinabrief;




export const pageQuery = graphql`
query {
  contentDatoms: allInternalBriefsJson {
    edges {
      node {
        body
        brief_id
        headline
        published_epoch_ts
        published_date_str
        published_date_word_str
        summary
        tags
        type
      }
    }
  }
}
`;
